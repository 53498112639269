<template>
  <div class="product-wrap product-lighting">
    <Header is-active="product" theme="white"></Header>
    <div class="empty-nav"></div>
    <div class="sub-banner banner-lighting"></div>
    <div class="white-wrap">
      <div class="txt-box align-ct no-indent pd-5">
        <p>智慧照明系统包含智能照明、智能采集和监测、室内定位、云计算、无线通信、环境监测、视频监控、 </p>
        <p>多媒体显示屏、WIFI、一键呼叫、广播、智慧灌溉、智慧闸机等一系列功能的平台化产品。 </p>
        <p>提供了超高节能效率的绿色智慧照明，深度贴合业务场景的人员、车辆、物料等精准定位管理。 </p>
        <p>实现了智能照明、绿色能源、智能安防、无线城市、互通互联、智能感知、智慧交通、智慧市政等诸多应用。 </p>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="fz-big align-ct"><h4>平台优势</h4></div>
      <div class="ys-pic">
        <img src="../../assets/img/product/ptys.png" alt="平台优势">
      </div>
    </div>
    <div class="white-wrap">
      <div class="max-center">
        <div class="fz-big align-ct"><h4>平台功能</h4></div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-zhzm"></i></div>
            <p>智慧照明</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gwbj"></i></div>
            <p>环境监测</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-sxt"></i></div>
            <p>视频监控</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-daping"></i></div>
            <p>智能大屏</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-kongzhi"></i></div>
            <p>智能控制</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-baojing"></i></div>
            <p>报警管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-nengyuan"></i></div>
            <p>能源管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-yscgq"></i></div>
            <p>故障检测</p>
          </div>
        </div>
        <div class="customized-box mt-5">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-zhkz"></i></div>
            <p>综合管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-anquan"></i></div>
            <p>安防管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-jieneng"></i></div>
            <p>节能管理</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-fenxi"></i></div>
            <p>报表分析</p>
          </div>
        </div>
      </div>
    </div>
    <div class="blue-wrap">
      <el-row class="max-center pd-5">
        <el-col :xs="24" :sm="16" :md="16" :lg="16" :xl="16">
          <div class="bs-pic"></div>
        </el-col>
        <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
          <div class="bs-info">
            <div class="fz-big align-lt"><h4>平台部署</h4></div>
            <p class="align-lt">支持公有云、私有云和混合云（公有云+私有云）三种部署方式。</p>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-2">
        <div class="trait-box">
          <el-row :gutter="30">
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="trait-item trait-a">
                <h6><span>节</span>能</h6>
                <div class="txt-info">
                  厂区和公共设定场景控制、人体及照度感应控制，将照明时长节能降耗。
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="trait-item trait-b">
                <h6><span>安</span>全</h6>
                <div class="txt-info">
                  弱电控强电，降低人工误操作风险；联动应急照明，火灾等突发事件强启应急照明，快速撤离。
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="trait-item trait-c">
                <h6><span>降</span>低运维成本</h6>
                <div class="txt-info">
                  区域或中央集控，提高工作效率；公共区域无人时保持基本照明可设定固定时间切换灯具，延长灯具寿命。
                </div>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="6" :lg="6" :xl="6">
              <div class="trait-item trait-d">
                <h6><span>智</span>能化管理</h6>
                <div class="txt-info">
                  系统将按预先设置自动运行，自由切换，可更好地改善厂区的工作环境，提高工作效率。
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="clean"></div>
        </div>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <div class="fz-big"><h4>系统界面</h4></div>
        <el-row :gutter="30" class="mt-5">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="auto-img">
              <img src="../../assets/img/product/lighting/pic_5.png" alt="系统界面">
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="auto-img">
              <img src="../../assets/img/product/lighting/pic_6.png" alt="系统界面">
            </div>
          </el-col>
        </el-row>
      </div>
      <div id="wrbz"></div>
    </div>
    <div class="white-wrap">
      <div class="max-center pd-5">
        <div class="fz-big"><h4>智能硬件</h4></div>
        <el-row :gutter="30" class="mt-2">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic a-pic"></div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="txt-box">
                超高光效185lm/W，搭配智能控制系统，实现按需用能，最高节能可达90%软硬件结合，实现灯具远程控制、任意分组、能耗监测、故障报警支持现场触屏及电脑端和手机端APP控制。模块化设计，简易工具维护，独立IP，自组网，可寻址。
              </div>
            </div>
            <div class="auto-img">
              <img src="../../assets/img/product/lighting/pic_8.png" alt="智能硬件">
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="grey-wrap">
      <div class="max-center pd-5">
        <el-row :gutter="30">
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-txt">
              <div class="fz-big align-lt" style="padding-top: 8%"><h4>定制化开发</h4></div>
              <div class="txt-box dzh-box">
                <p class="em">定制的不仅仅是软件和硬件 更是一种理念</p>
                <p>定制化开发是解决企业个性化难题唯一途径，而且是企业快速发展和提高竞争力的最好方法。</p>
                <el-button type="primary" plain class="zdh-btn" @click="handleShow">立即咨询</el-button>
              </div>
            </div>
          </el-col>
          <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
            <div class="demo-pic dzh-pic"></div>
          </el-col>
        </el-row>
        <div class="fz-sm align-ct" style="padding-top: 4%"><h5>定制化特点</h5></div>
        <div class="customized-box">
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-gps"></i></div>
            <p>针对性强</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-bianjie"></i></div>
            <p>操作便捷</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-price"></i></div>
            <p>性价比高</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-service"></i></div>
            <p>服务周到</p>
          </div>
          <div class="td-item">
            <div class="td-pic"><i class="iconfont icon-upgrade"></i></div>
            <p>升级方便</p>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <BackTop ref="backTop"></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '智慧照明-产品中心,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控智慧照明-产品中心,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {
    handleShow () {
      this.$refs.backTop.handleOpen()
    }
  }
}
</script>
<style lang="less" scoped>
@import "../../../public/css/product.less";

.product-lighting {
  .banner-lighting {
    background: url("../../assets/img/product/lighting/banner.jpg") no-repeat center;
    background-size: cover;
    position: relative;
  }

  // 平台特点
  .trait-box {
    width: 100%;
    height: auto;
    text-align: center;

    .trait-item {
      width: 100%;
      background-color: #cccccc;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: 2px solid rgb(186, 180, 180);
      box-sizing: border-box;
      margin-bottom: 30px;

      h6 {
        text-align: left;
        text-indent: 1em;
        line-height: 2;
        padding-top: 20px;
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #1890FF;

        span {
          padding-bottom: 5px;
          border-bottom: 2px solid #1890FF;
        }
      }

      .txt-info {
        width: 80%;
        padding-top: 10px;
        margin: 0 auto;
        text-align: left;
      }
    }

    .trait-item::before {
      content: '';
      padding-top: 100%;
      float: left;
    }

    .trait-item::after {
      content: '';
      display: block;
      clear: both;
    }

    .trait-a {
      background-image: url("../../assets/img/product/lighting/bg_a.png");
    }

    .trait-b {
      background-image: url("../../assets/img/product/lighting/bg_b.png");
    }

    .trait-c {
      background-image: url("../../assets/img/product/lighting/bg_c.png");
    }

    .trait-d {
      background-image: url("../../assets/img/product/lighting/bg_d.png");
    }
  }

  .a-pic {
    background-image: url("../../assets/img/product/lighting/pic_7.jpg");
  }

}

@media (max-width: 749px) {
  .product-lighting {

    // 平台特点
    .trait-box {
      .trait-item {
        h6 {
          text-align: left;
          line-height: 1.5;
          padding-top: 15px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #1890FF;

          span {
            padding-bottom: 0px;
            border-bottom: 2px solid #1890FF;
          }
        }

        .txt-info {
          width: 88%;
          font-size: 14px;
        }
      }
    }
  }
}

</style>
